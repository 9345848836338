/* Reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
input, textarea, select, button, a, img {
    outline: none !important;
    resize: none;
    border: 0;
    border-color: transparent;
	outline-style: none;
	-webkit-outline-style: none;
    -moz-outline-style: none;
}
strong { font-weight: 700; }
button {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
}

/* Border radius */
.br1 {
	border-radius: 1px;
	-moz-border-radius: 1px;
	-webkit-border-radius: 1px;
	-ms-border-radius: 1px;
	-o-border-radius: 1px;
}
.br2 {
	border-radius: 2px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
}
.br3 {
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
}
.br4 {
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}
.br5 {
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
}
.br6 {
	border-radius: 6px;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}
.br7 {
	border-radius: 7px;
	-moz-border-radius: 7px;
	-webkit-border-radius: 7px;
	-ms-border-radius: 7px;
	-o-border-radius: 7px;
}
.br8 {
	border-radius: 8px;
	-moz-border-radius: 8px;
	-webkit-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}
.br9 {
	border-radius: 9px;
	-moz-border-radius: 9px;
	-webkit-border-radius: 9px;
	-ms-border-radius: 9px;
	-o-border-radius: 9px;
}
.br10 {
	border-radius: 10px;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
}
div.maxWidthCtr {
	width: 100%;
	max-width: 1200px;
	height: auto;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	padding: 0 20px;
	margin: 0 auto;
}
div.gridContainer {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 50px;
}

/* Structure */
*, *:after, *:before {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
}

/* Custom */
body {
    line-height: 1;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
	background-color: #323F4F;
	color: #EEEEEE;
}
header {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: row;
	width: 100%;
	height: auto;
	background-color: #000000;
	padding: 25px 20px;
}
header > img {
	width: auto;
	max-height: 100px;
}

/* Elements */
img.sectionPreloader {
	margin: 200px auto 0;
	width: 120px;
	height: 120px;
}
